<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <h3 class="mb-3">Minhas informações</h3>
        <b-form @submit="submitChangeUserName">
          <form-input
            v-model="formUserName.name"
            label="Seu nome:"
            placeholder="Informe seu nome"
            icon="person"
          />

          <form-input
            :value="formUserName.email"
            label="Seu email:"
            icon="at"
            disabled
          />
          <b-button type="submit">ALTERAR NOME</b-button>
        </b-form>
      </b-col>
      <b-col>
        <h3 class="mb-3">Senha</h3>
        <b-form @submit="submitChangePassword">
          <form-password
            v-model="formPassword.currentPassword"
            label="Senha atual:"
            placeholder="Informe sua senha atual"
            type="password"
            icon="lock"
            required
          />

          <form-password
            v-model="formPassword.newPassword"
            label="Nova senha:"
            placeholder="Informe sua nova senha"
            type="password"
            icon="lock"
            required
          />
          <b-button type="submit">ALTERAR SENHA</b-button>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import FormInput from "../../components/form/FormInput.vue";
import FormPassword from "../../components/form/FormPassword.vue";
import http from "../../plugins/http";

export default {
  name: "UserProfile",
  components: { FormInput, FormPassword },
  data() {
    return {
      formPassword: {
        currentPassword: "",
        newPassword: "",
      },
      formUserName: { ...this.$store.state.auth.user },
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  methods: {
    ...mapActions("auth", ["changePassLoggedin"]),
    ...mapMutations(["setToastedSuccess", "setToastedError"]),

    async submitChangePassword(event) {
      event.preventDefault();
      await this.changePassLoggedin(this.formPassword);
    },
    async submitChangeUserName(event) {
      event.preventDefault();
      try {
        await http.patch("user/name", this.formUserName);
        this.setToastedSuccess({
          message: "Nome alterado com sucesso",
        });
      } catch (err) {
        this.setToastedError({
          message: "Não foi possível alterar o nome",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
