<template>
  <b-form-group :label="label">
    <b-input-group>
      <b-input-group-prepend v-if="icon" is-text>
        <b-icon :icon="icon" />
      </b-input-group-prepend>

      <b-form-input
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        :required="required"
        :type="type"
        :state="validationState"
        @input="$emit('input', $event)"
      />

      <b-form-invalid-feedback>
        {{ validationMessage }}
      </b-form-invalid-feedback>

      <b-input-group-append
        is-text
        class="icon-button"
        :title="toggleEye ? 'Visualizar senha' : 'Esconder senha'"
        @click="seePassword"
      >
        <b-icon-eye v-if="toggleEye" />
        <b-icon-eye-slash v-else />
      </b-input-group-append>
    </b-input-group>
  </b-form-group>
</template>

<script>
export default {
  name: "FormInput",
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    icon: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      toggleEye: true,
      type: "password",
    };
  },
  computed: {
    validationState() {
      return this.errors[0] ? false : null;
    },
    validationMessage() {
      return this.errors[0];
    },
  },
  methods: {
    seePassword() {
      this.toggleEye = !this.toggleEye;
      this.type = this.toggleEye ? "password" : "text";
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-button {
  cursor: pointer;
}
</style>
